
import Navigation from "./Navigation";
export default {
  components: {
    Navigation,
  },
  data() {
    return {
      tab: "",
      tabList: null,
      drawer: false,
      model: "one",
      lang: this.$router.currentRoute.params.lang,
    };
  },
  mounted() {
    
    this.getContent();
  },
  methods: {
    async getContent() {
      const { results } = await this.$prismic.client.query([
        this.$prismic.Predicates.at("document.type", "header"),
        this.$prismic.Predicates.at("document.tags", [this.lang]),
      ]);
      this.tabList = results[0].data;
    },
    ToPage(url) {
      this.$router.push(`/${this.lang}${url}`);
    },
    Navigation() {
      this.drawer = !this.drawer;
    },
    ChangeLanguage(x) {
      let lang = this.$router.currentRoute.path;
      if (x === "th") {
        lang = lang.replace("en", "th");
      } else {
        lang = lang.replace("th", "en");
      }
      this.$router.push(lang);
      this.lang = x;
      this.getContent();
    },
  },
  watch: {
    $route() {
      this.lang = this.$router.currentRoute.params.lang;
      this.getContent();
    },
    // lang() {
    //   console.log(this.lang);
    // },
  },
};
