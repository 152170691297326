
export default {
  data() {
    return {
      tab: "",
      tabList: "",
      lang: this.$router.currentRoute.params.lang,
    };
  },
  watch: {
    $route() {
      this.lang = this.$router.currentRoute.params.lang;
      this.getContent();
    },
  },
  mounted() {
    this.getContent();
  },
  methods: {
    async getContent() {
      const { results } = await this.$prismic.client.query([
        this.$prismic.Predicates.at("document.type", "header"),
        this.$prismic.Predicates.at("document.tags", [this.lang]),
      ]);
      this.tabList = results[0].data;
      // console.log(this.tabList);
    },
    CloseNavigation() {
      this.$emit("clicked", false);
    },
    ChangeLanguage(x) {
      let lang = this.$router.currentRoute.path;
      if (x === "th") {
        lang = lang.replace("en", "th");
      } else {
        lang = lang.replace("th", "en");
      }
      this.$router.push(lang);
      this.lang = x;
      this.getContent();
    },
    ToPage(url) {
      this.$router.push(`/${this.lang}${url}`);
    },
  },
};
