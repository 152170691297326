import { render, staticRenderFns } from "./Toolbar.vue?vue&type=template&id=6b13d806&scoped=true"
import script from "./Toolbar.vue?vue&type=script&lang=js"
export * from "./Toolbar.vue?vue&type=script&lang=js"
import style0 from "./Toolbar.vue?vue&type=style&index=0&id=6b13d806&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b13d806",
  null
  
)

export default component.exports
import {QToolbar,QBtn,QImg,QSpace,QIcon,QBtnDropdown,QList,QItem,QItemLabel,QSeparator,QTabs,QTab,QDrawer} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QToolbar,QBtn,QImg,QSpace,QIcon,QBtnDropdown,QList,QItem,QItemLabel,QSeparator,QTabs,QTab,QDrawer})
