import { render, staticRenderFns } from "./Navigation.vue?vue&type=template&id=00016816&scoped=true"
import script from "./Navigation.vue?vue&type=script&lang=js"
export * from "./Navigation.vue?vue&type=script&lang=js"
import style0 from "./Navigation.vue?vue&type=style&index=0&id=00016816&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00016816",
  null
  
)

export default component.exports
import {QBtn,QTabs,QTab,QBtnGroup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QBtn,QTabs,QTab,QBtnGroup})
